import styled from 'styled-components';

const StatsStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RowStyle = styled.div`
    &:first-child {
        text-align: right;
        margin-right: 1.5px;

        > div {
            justify-content: flex-end;

            span {
                margin-left: 10px;
            }
        }
    }

    &:last-child {
        text-align: left;
        margin-left: 1.5px;

        > div {
            justify-content: flex-start;

            span {
                margin-right: 10px;
            }
        }
    }
`;

const PointStyle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 3px;

    color: rgb(131, 131, 131);
    line-height: 1;
    font-weight: bold;

    span {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 80px;
        height: 35px;
        background-color: ${props => props.theme.primaryColor}

        color: #000;
        font-size: 25px;
    }
`;

export {
    StatsStyle,
    RowStyle,
    PointStyle
};
