import React, { Component } from 'react';
import { Icon } from 'antd';
import {
	ContainerStyle,
	SelectGroupStyle,
	TableStyle,
	PlayerStyle,
} from './PlayerListStyle';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import PlayerPortrait from '../PlayerPortrait/PlayerPortrait';
import Select from '../../components/UI/Select/Select';
import Popover from '../../components/UI/Popover/Popover';
import { PlayerType } from '../Player/Player';
import { Club, Player, OnPickType, IsPickAbleType } from './../../types';
import { roundNextHalf } from './../../lib/helpers';
import { BikeSvg, EuroSvg } from './../../styles/custom-icons';

const BikeIcon = (props: any) => <Icon component={BikeSvg} {...props} />;
const EuroIcon = (props: any) => <Icon component={EuroSvg} {...props} />;

export interface PlayerListProps {
	data: any[];
	size: number;
	action: boolean;
	isLoading?: boolean;
	hidePositions?: boolean;
	onPick?: OnPickType;
	onSelect?: any;
	isPickAble?: IsPickAbleType;
	showHeader?: boolean;
	playerType?: PlayerType;
	clubs: any[];
	assetsCdn: string;
	actionLabel?: string;
	playerTax?: number | undefined;
}

export interface PlayerListState {}

class PlayerList extends Component<PlayerListProps, PlayerListState> {
	state = {
		filters: {
			search: '',
			budget: -1,
			club: -1,
		},
	};

	onFilterChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, {
			[name]: value,
		});
		this.setState({ filters });
	};

	playerFilter = (player: Player, filters: any) => {
		let show = true;

		if (
			filters.search.length &&
			player.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1
		) {
			show = false;
		}

		if (filters.club !== -1 && filters.club !== player.clubId) {
			show = false;
		}

		if (filters.budget !== -1 && player.value > filters.budget) {
			show = false;
		}
		return show;
	};

	render() {
		const {
			data,
			action,
			size,
			showHeader,
			clubs,
			hidePositions,
			playerType,
			isLoading,
			assetsCdn,
			actionLabel,
			playerTax,
		} = this.props;

		const clubsList = [{ 
				id: -1, 
				name: <span className={'prefixed-label'}> <BikeIcon style={{marginRight: 5}}/> Alle ploegen </span> 
			}]
			.concat(clubs);

		const playersList = data.filter(player =>
			this.playerFilter(player, this.state.filters)
		);

		const budgets = [
			{ text: <span className={'prefixed-label'}> <EuroIcon/> Onbeperkt budget </span>, value: 100 },
			{ text: 'Minder dan 20 miljoen', value: 19 },
			{ text: 'Minder dan 18 miljoen', value: 17 },
			{ text: 'Minder dan 16 miljoen', value: 15 },
			{ text: 'Minder dan 14 miljoen', value: 13 },
			{ text: 'Minder dan 12 miljoen', value: 11 },
			{ text: 'Minder dan 10 miljoen', value: 9 },
			{ text: 'Minder dan 8 miljoen', value: 7 },
			{ text: 'Minder dan 6 miljoen', value: 5 },
			{ text: 'Minder dan 4 miljoen', value: 3 },

		];

		const positions = [
			{ id: -1, name: 'Alle posities' },
			{ id: 1, name: 'Doelman' },
			{ id: 2, name: 'Verdediger' },
			{ id: 3, name: 'Middenvelder' },
			{ id: 4, name: 'Aanvaller' },
		];

		const columns = [
			{
				title: '',
				key: 'avatar',
				dataIndex: 'avatar',
				width: '20%',
				render: (text: string, record: any) => {
					const icon = `${assetsCdn}/jerseys/cycling/${record.clubId}.png`;
					return (
						<PlayerPortrait
							icon={icon}
							type={playerType || PlayerType.SoccerPortrait}
						/>
					);
				},
			},
			{
				key: 'name',
				title: 'Player',
				dataIndex: 'name',
				width: '40%',
				render: (text: string, record: any) => {
					const club = clubs.find(club => club.id === record.clubId);
					const position = positions.find(
						position => position.id === record.positionId
					);
					return (
						<React.Fragment>
							<PlayerStyle
								type="mobile"
								clubColor={'#0E5EA0'}
								position={record.positionId}
							>
								<p>{record.name}</p>
							</PlayerStyle>
							<PlayerStyle
								type="desktop"
								clubColor={'#0E5EA0'}
								position={record.positionId}
							>
								<p>{record.surname} {record.forename}</p>
								<p>
									{club && club.name}
									<span>
										{(!hidePositions && position && position.name) || null}
									</span>
								</p>
							</PlayerStyle>
						</React.Fragment>
					);
				},
			},
			{
				key: 'value',
				title: 'Value',
				dataIndex: 'value',
				width: '20%',
				render: (playerValue: number) => {
					const calculatedPlayerValue = !playerTax
						? playerValue
						: roundNextHalf(playerValue + (playerValue * playerTax) / 100);

					const infoValueContent = (
						<div>
							{(playerTax && (
								<span>
									{`${calculatedPlayerValue}M = ${playerValue}M + ${calculatedPlayerValue -
										playerValue}M (${playerTax}% belasting)`}
								</span>
							)) ||
								null}
							<br />
							{(playerTax && (
								<i style={{ fontSize: '10px' }}>
									{`Afgerond van ${(
										(playerValue * playerTax) /
										100
									).toFixed(2)}M naar ${calculatedPlayerValue - playerValue}M`}
								</i>
							)) ||
								null}
						</div>
					);

					return (
						<span>
							€{calculatedPlayerValue}M
							{(playerTax && (
								<Popover content={infoValueContent} title={false}>
									<Icon
										style={{ paddingLeft: '5px', cursor: 'pointer' }}
										type="info-circle"
										theme="filled"
									/>
								</Popover>
							)) ||
								null}
						</span>
					);
				},
			},
		];

		if (action) {
			columns.push({
				key: 'action',
				title: 'Pick player',
				dataIndex: 'action',
				width: '20%',
				render: (text: string, record: any) => {
					return (
						(this.props.isPickAble && this.props.isPickAble(record) && (
							<Button
								type="primary"
								onClick={(e: any) =>
									this.props.onPick && this.props.onPick(record)
								}
								style={{width: '100%'}}
								size="small"
							>
								{actionLabel || 'Kies'}
							</Button>
						)) || <span />
					);
				},
			});
		}

		let tableEventHandler: any = () => {};

		if (this.props.onSelect) {
			tableEventHandler = (player: any) => ({
				onClick: (event: any) => {
					this.props.onSelect(player);
				},
			});
		}

		return (
			<ContainerStyle>
				{
					<Input
						prefix={<Icon type="search" />}
						type="text"
						placeholder="Zoeken op naam…"
						name="search"
						onChange={(event: any) =>
							this.onFilterChange(event.target.name, event.target.value)
						}
						style={{ margin: 0 }}
					/>
				}
				<SelectGroupStyle>
					<Select
						block
						values={clubsList}
						style={{marginLeft: 0}}
						keyProperty={'id'}
						onSelect={(value: any) => this.onFilterChange('club', value)}
						textProperty={'name'}
						placeholder={clubsList[0].name}
					/>
					{(!hidePositions && (
						<Select
							block
							keyProperty={'id'}
							textProperty={'name'}
							onSelect={(value: any) => this.onFilterChange('position', value)}
							values={positions}
							placeholder={positions[0].name}
						/>
					)) ||
						null}
					<Select
						block
						values={budgets}
						style={{marginRight: 0}}
						keyProperty={'value'}
						onSelect={(value: any) => this.onFilterChange('budget', value)}
						textProperty={'text'}
						placeholder={budgets[0].text}
					/>
				</SelectGroupStyle>
				<TableStyle
					columns={columns}
					dataSource={playersList}
					showHeader={showHeader}
					locale={{ emptyText: 'Geen renners gevonden.' }}
					loading={isLoading}
					pagination={{ pageSize: size, showLessItems: true }}
					onRow={tableEventHandler}
					rowKey="id"
					rowClassName={(record: object, index: number) =>
						`${index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'} ${
							this.props.onSelect ? 'cursor-pointer' : ''
						}`
					}
				/>
			</ContainerStyle>
		);
	}
}

export default PlayerList;
