import React, { Component } from "react";
import { Form, notification, Select } from 'antd';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';
import lockr from 'lockr';
import moment from 'moment';
import { H3 } from "../../styles/global";

import { Button, Input, InputPassword, Checkbox, FormItem, DatePicker } from '..';
import * as userActions from '../../actions/user'

import { RegisterStyle } from "./RegisterStyle";

export interface RegisterFormProps {
	form: any
}

export interface RegisterFormValues {
	firstName: string,
	lastName: string,
	email: string,
	password: string,
	dob: string,
	termsAccepted: boolean,
	street: string,
	street_nr: string,
	post_code: string,
	city: string,
	commercialAccepted: boolean
}

class RegisterForm extends Component<RegisterFormProps> {

	handleSubmit = (e: any) => {
		e.preventDefault();
		this.props.form.validateFields((err: any, values: RegisterFormValues) => {
			if (!err) {
				userActions.register(
					values.firstName,
					values.lastName,
					values.email,
					values.password,
					moment(values.dob).format('YYYY-MM-DD'),
					values.street,
					values.street_nr,
					values.post_code,
					values.city,
					values.commercialAccepted
				)
					.then((result: any) => {
						if (result && result.token) {
							const user = pick(result, ['id', 'firstName', 'lastName', 'city', 'street', 'streetNr', 'postCode']);
							lockr.set('token', result.token);
							lockr.set('user', user);

							// @ts-ignore
							window.dataLayer.push({
								event: 'account_created',
								authentication_type: 'mail',
								user_id: user.id
							});

							window.location.href = '/new';
						}
					})
					.catch(() => {
						notification.error({ message: 'Email already exists!' });
					})
			}
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const dobFormat = 'DD/MM/YYYY';

		const { Option } = Select;

		return (
			<RegisterStyle>
				<Form onSubmit={this.handleSubmit} className="register-form">
					<H3 style={{ textAlign: 'left' }}>Registreer en speel mee</H3>
					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('firstName', {
							rules: [{ required: true, message: 'Vul je voornaam in!' }],
						})(
							<Input placeholder="Voornaam" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('lastName', {
							rules: [{ required: true, message: 'Vul je achternaam in!' }],
						})(
							<Input placeholder="Achternaam" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('street', {
							rules: [{ required: true, message: 'Vul je straat in!' }],
						})(
							<Input placeholder="Straat" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('street_nr', {
							rules: [{ required: true, message: 'Vul je straatnummer in!' }],
						})(
							<Input placeholder="Nummer" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('post_code', {
							rules: [{ required: true, message: 'Vul je postcode in!' }],
						})(
							<Input placeholder="Postcode" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('city', {
							rules: [{ required: true, message: 'Vul je gemeente in!' }],
						})(
							<Input placeholder="Gemeente" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('dob', {
							rules: [
								{
									required: true,
									message: 'Vul je geboortedatum in!'
								},
								{
									validator: (rule: any, value: any, cb: any) => {
										const atLeast18YO = value && (moment().diff(value, 'years') >= 18);
										if (atLeast18YO) {
											cb();
										} else {
											cb('Je moet minstens 18 jaar oud zijn!');
										}
									}
								}
							],
						})(
							<DatePicker format={dobFormat} placeholder="Geboortedatum" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('email', {
							rules: [{ required: true, message: 'Vul je e-mailadres in!' }],
						})(
							<Input type="email" placeholder="E-mailadres" />
						)}
					</FormItem>

					<FormItem style={{ marginBottom: '0px' }}>
						{getFieldDecorator('password', {
							rules: [
								{ required: true, message: 'Vul je wachtwoord in!' },
								{ min: 6, message: 'Het wachtwoord moet minstens 6 tekens lang zijn!' }],
						})(
							<InputPassword type="password" placeholder="Wachtwoord" />
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator('termsAccepted', {
							initialValue: false,
							rules: [{
								required: true,
								transform: (value: any) => (value || undefined),
								type: 'boolean',
								message: 'Gelieve de voorwaarden te aanvaarden'
							}],
						})(
							<Checkbox>
								Door te registreren, ga ik akkoord met het <Link to={'/rules'} style={{ textDecoration: 'underline' }}>wedstrijdreglement</Link>, en ik erken het privacybeleid en cookiebeleid te hebben gelezen.
							</Checkbox>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator('commercialAccepted', {
							initialValue: false,
							rules: [{ required: false }],
						})(
							<Checkbox>
								Hou me op de hoogte van nieuws, toekomstige acties en promoties van de Gouden Spelen via elektronisch bericht.
							</Checkbox>
						)}
					</FormItem>

					<Button type="primary" htmlType="submit" style={{ marginBottom: '20px', width: '100%' }}>Registreer</Button>
					<p><Link to={`/login`} style={{ textDecoration: 'underline' }}>Al een account? Log in</Link></p>
				</Form>
			</RegisterStyle>
		);
	}
}

export default RegisterForm;