import React, { Component } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Card, Form } from 'antd';
import { Link } from 'react-router-dom';
import lockr from 'lockr';
import Media from 'react-media';

import * as userActions from '../../actions/user'
import { StoreState, UserState, MatchesState, ApplicationState } from './../../types';
import { Button, Input, InputPassword, Checkbox, Team, Title, TitleType, FormItem, RegisterForm, Row, Col } from './../../components';
import config from './../../config';
import { HomeStyle } from "./HomeStyles";
import { H2, PageStyle, H1, H3 } from "../../styles/global";
import { mediaQueries, sizes } from '../../styles/media-queries';
import { Redirect } from "react-router";
import { notification } from 'antd';
import Login from "../Login/Login";

export interface Props {
	history: any;
	user: UserState;
	match: any;
	matches: MatchesState;
	application: ApplicationState;
	onLogOut?: () => void;
	t: any;
}

export interface State {

}
const WrappedRegisterForm = Form.create()(RegisterForm);

class Home extends Component<Props> {
	state = {
		
	}

	constructor(props: Props) {
		super(props);
	}

	shouldComponentUpdate(nextProps: Props) {
		if(this.props.user.authenticated && !nextProps.user.authenticated) {
			return true;
		}
		return false;
	}

	render() {
		return (
			<HomeStyle application={this.props.application}>
				{ 
					!this.props.user.authenticated ?
						<div className="game-cover">
							<Row>
								<Col sm={{ span: 24 }}>
									<H2>Welkom bij Gouden Vuelta!</H2>
									<p>De Gouden Vuelta is een fantasy wielerspel van HLN voor de Ronde van Spanje</p>
									<Login t={this.props.t} />
								</Col>
							</Row>
						</div>
					: null 
				}
				<PageStyle>
					<Row><Col md={{ span: 24 }} style={{textAlign: 'center'}}><H3>{this.props.t(`game.${this.props.application.competition.competitionFeed}.howItWorks`)}</H3></Col></Row>
					<Row>
						<Col md={{ span: 8 }} sm={{ span: 24 }} className="how-to-play">
							<div className="step">
								<img src={`https://goudenspelen.s3-eu-west-1.amazonaws.com/files/onboarding/${this.props.application.competition.competitionFeed}_step_1.jpg`} />
								<div className="label"><h3>{this.props.t(`game.${this.props.application.competition.competitionFeed}.titleA`)}</h3></div>
								<p>{this.props.t(`game.${this.props.application.competition.competitionFeed}.bodyA`)}</p>
							</div>
						</Col>
						<Col md={{ span: 8 }} sm={{ span: 24 }} className="how-to-play">
							<div className="step">
								<img src={`https://goudenspelen.s3-eu-west-1.amazonaws.com/files/onboarding/${this.props.application.competition.competitionFeed}_step_2.jpg`} />
								<div className="label"><h3>{this.props.t(`game.${this.props.application.competition.competitionFeed}.titleB`)}</h3></div>
								<p>{this.props.t(`game.${this.props.application.competition.competitionFeed}.bodyB`)}</p>
							</div>
						</Col>
						<Col md={{ span: 8 }} sm={{ span: 24 }} className="how-to-play">
							<div className="step">
								<img src={`https://goudenspelen.s3-eu-west-1.amazonaws.com/files/onboarding/${this.props.application.competition.competitionFeed}_step_3.jpg`} />
								<div className="label"><h3>{this.props.t(`game.${this.props.application.competition.competitionFeed}.titleC`)}</h3></div>
								<p>{this.props.t(`game.${this.props.application.competition.competitionFeed}.bodyC`)}</p>
							</div>
						</Col>
					</Row>
				</PageStyle>
			</HomeStyle>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {
		onLogOut: () => dispatch(userActions.logOut()),
	}
}

export function mapStateToProps({ user, matches, application }: StoreState.All) {
	return {
		user,
		matches,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);